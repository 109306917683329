import axios from '@/plugins/axios'

export default {

  async list (data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`products?${params}`)
  },

  async get (id) {
    return await axios.get(`products/${id}`)
  },

  async insert (data) {
    return await axios.post('products', data)
  },

  async update (id, data) {
    return await axios.put(`products/${id}`, data)
  },

  async delete (id) {
    return await axios.delete(`products/${id}`)
  },

}
