<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">
      <v-row>
        <v-col>
          <v-btn color="success" class="mr-2" dark @click="openDialog()">
            <v-icon left v-text="'mdi-plus'" />
            Adicionar
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-sheet elevation="1">
            <v-data-table
              disable-filtering
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="products"
              :options.sync="pagination"
              :footer-props="{
                'items-per-page-options': [10, 20, 50],
              }"
              @update:options="loadProducts"
            >
              <template v-slot:[`item.actions`]="{ item }" class="text-right">
                <v-btn icon small @click="openDialog(item)">
                  <v-icon v-text="'mdi-pencil'" small />
                </v-btn>
                <v-btn icon small>
                  <v-icon
                    v-text="'mdi-delete'"
                    small
                    @click="clickDeleteItem(item)"
                  />
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <dialog-product
      v-model="showDialogProduct"
      :product-id="editId"
      @save="loadProducts"
    />

    <dialog-delete
      v-model="showDialogDelete"
      :loading="loadingDelete"
      @confirm="deleteItem"
    />
  </div>
</template>

<script>
import productsApi from '@/api/products'

export default {
  components: {
    DialogDelete: () => import('@/components/admin/dialog/DialogDelete'),
    DialogProduct: () =>
      import('@/components/admin/dialog/products/DialogProduct'),
  },

  data() {
    return {
      loading: false,
      loadingDelete: false,
      showDialogProduct: false,
      showDialogDelete: false,
      count: 0,
      deleteId: null,
      editId: null,
      headers: [
        { text: '#', value: 'id' },
        { text: 'Nome', value: 'name' },
        { text: 'Descrição', value: 'description' },
        { text: '', value: 'actions', align: 'right' },
      ],
      products: [],
      pagination: {},
    }
  },

  methods: {
    async loadProducts() {
      try {
        this.loading = true

        const response = await productsApi.list({
          limit: this.pagination.itemsPerPage,
          offset:
            this.pagination.page * this.pagination.itemsPerPage -
            this.pagination.itemsPerPage,
        })

        this.products = response.data.products
        this.count = response.data.count
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loading = false
      }
    },

    openDialog(product = null) {
      this.editId = product ? product.id : null
      this.showDialogProduct = true
    },

    async clickDeleteItem(product) {
      this.deleteId = product.id
      this.showDialogDelete = true
    },

    async deleteItem() {
      try {
        this.loadingDelete = true

        await productsApi.delete(this.deleteId)

        this.showDialogDelete = false
        this.loadProducts()

        this.$snackbar.show({
          color: 'success',
          message: this.$messages._('delete_success'),
        })
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingDelete = false
        this.dialogDelete = false
      }
    },
  },
}
</script>
